import React from "react";
import FloatingIcon from "../../../Components/UI/Floating-Icon/floating-icon";
import Footer from "../../../Components/Layouts/Footer/footer";
import Navbar from "../../../Components/Layouts/Navbar/navbar";
import Banner from "../../../Components/Layouts/Banner/banner";

const Assembly = () => {
  return (
    <div className='flex flex-col'>
      <Navbar />
      <div className=''>
        <Banner title={"Assembly Manufacturing"} image='assembly.jpeg' />

        <div className='sm:m-2'>
          <div className='flex flex-col sm:flex-row p-4 lg:p-8'>
            <div className='relative m-2'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/assembly/assembly 1.jpg"}
                alt='assembly 1'
              />
            </div>
            <div className='relative m-2'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/assembly/assembly 2.jpg"}
                alt='assembly 2'
              />
            </div>
            <div className='relative m-2'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/assembly/assembly 3.jpg"}
                alt='assembly 3'
              />
            </div>
            <div className='relative m-2'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/assembly/assembly 4.jpg"}
                alt='assembly 4'
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <FloatingIcon />
    </div>
  );
};

export default Assembly;
