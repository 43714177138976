import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ProductCard from "../ProductCard/productcard";
import Divider from "../../UI/Divider/divider";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { API } from "../../../API/API";

interface ProductLayoutProps {
  name: string;
}

const ProductLayout = () => {
  const { brandProps } = useParams();
  const [brand, setbrand] = useState<ProductLayoutProps[]>([]);
  const [currbrand, setCurrBrand] = useState(
    brandProps === undefined ? "All Product" : brandProps
  );
  const [product, setProduct] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [brand]);

  useEffect(() => {
    getBrand();
    getProduct();
  }, [currbrand]);

  const getBrand = async () => {
    const response = await API.get("/brand");
    setbrand(response.data);
    try {
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
    }
  };

  const getProduct = async () => {
    const response = await API.get("/product");
    setProduct(response.data);
    try {
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
    }
  };

  return (
    <section className='w-full my-8 sm:my-16 h-full mx-auto flex flex-col sm:flex-row gap-4'>
      <div className='relative'>
        <div className='px-4 sm:px-0 sm:sticky sm:top-4'>
          <aside
            id='separator-sidebar'
            className='border-2 rounded-2xl w-full sm:w-48 xl:w-64 max-h-fit sm:h-screen flex mx-auto'
            aria-label='Sidebar'
          >
            <div className='h-full px-3 py-4 w-full sm:w-48 xl:w-64'>
              <ul className='space-y-2 font-medium'>
                <li>
                  <span className='flex items-center p-2 text-gray-900 rounded-lg group ms-3 text-xl sm:text-lg xl:text-xl'>
                    CATEGORY
                  </span>
                  <Divider />
                </li>
                <li
                  key={"All Product"}
                  onClick={() => setCurrBrand("All Product")}
                >
                  <span
                    className={`flex items-center p-2 text-gray-900 rounded-lg group text-lg sm:text-base xl:text-lg hover:bg-gray-200 ${
                      currbrand === "All Product" ? "bg-gray-200" : "bg-white"
                    }`}
                  >
                    <p className='ms-3'>ALL PRODUCT</p>
                  </span>
                </li>
                {brand && brand.length > 0 ? (
                  brand.map((item, itemIndex) => (
                    <li key={itemIndex} onClick={() => setCurrBrand(item.name)}>
                      <span
                        className={`flex items-center p-2 text-gray-900 rounded-lg group text-lg sm:text-base xl:text-lg hover:bg-gray-200 ${
                          currbrand === item.name ? "bg-gray-200" : "bg-white"
                        }`}
                      >
                        <p className='ms-3'>{item.name}</p>
                      </span>
                    </li>
                  ))
                ) : (
                  <li>Failed Fetch brand</li>
                )}
              </ul>
            </div>
          </aside>
        </div>
      </div>

      <ProductCard data={product} filter={currbrand} loading={loading} />
    </section>
  );
};

export default ProductLayout;
