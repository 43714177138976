import React from "react";
import Navbar from "../../Components/Layouts/Navbar/navbar";
import Footer from "../../Components/Layouts/Footer/footer";
import FloatingIcon from "../../Components/UI/Floating-Icon/floating-icon";
import Banner from "../../Components/Layouts/Banner/banner";
import "./services.css";
import { useNavigate } from "react-router";

const Services = () => {
  const navigate = useNavigate();
  const features = [
    {
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z'
          />
        </svg>
      ),
      title: "Service Pump and Motor",
      desc: "We provide maintenance and repair services for hydraulic pumps and motors to ensure optimal performance and extended lifespan. Our experienced team of technicians is ready to assist you with quick and efficient solutions.",
      href: "/service",
    },
    {
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z'
          />
        </svg>
      ),
      title: "Assembly Manufacturing",
      desc: "We offer high-quality hydraulic component assembly and manufacturing services. Utilizing the latest technology and premium materials, we guarantee reliable and durable products for various industrial applications.",
      href: "/assembly",
    },
    {
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z'
          />
        </svg>
      ),
      title: "Test Banch",
      desc: "We provide testing facilities to ensure that every hydraulic product meets the highest quality and performance standards. With advanced equipment, we conduct thorough testing to give you complete confidence in the products you use.",
      href: "/test",
    },
  ];

  return (
    <div className='flex flex-col'>
      <Navbar />
      <div className=''>
        <Banner title='Our Facilities' image='service-banner.jpg' />
        <section className='py-8'>
          <div className='max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8'>
            <div className='items-center gap-x-12 sm:px-4 md:px-0 lg:flex mx-8 sm:py-8'>
              <div className='px-4 space-y-3 mt-6 sm:px-0 md:mt-0 text-center lg:mx-8 xl:mx-20'>
                <p className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                  Optimize Performance, Enhance Reliability
                </p>
                <p className='mt-3 text-gray-600'>
                  We are equipped with state-of-the-art facilities to ensure the
                  highest quality service for our clients. Our Test Bench allows
                  us to conduct precise testing and calibration of hydraulic
                  pumps and motors, ensuring optimal performance. Our Service
                  Pump and Motor facility provides comprehensive repair and
                  maintenance services, handled by skilled technicians to extend
                  the life and efficiency of your equipment. Additionally, our
                  Assembly area is dedicated to the meticulous construction and
                  assembly of hydraulic systems, ensuring every component meets
                  our strict quality standards.
                </p>
              </div>
            </div>
            <div className='relative my-12'>
              <div className='grid gap-8 sm:grid-cols-2 lg:grid-cols-3'>
                {features.map((item, idx) => (
                  <a
                    key={idx}
                    className='bg-white space-y-3 p-4 border rounded-lg cursor-pointer hover:scale-105 hover:bg-gray-50 duration-300'
                    href={item.href}
                  >
                    <div className='text-third pb-3'>{item.icon}</div>
                    <h4 className='text-lg text-gray-800 font-semibold'>
                      {item.title}
                    </h4>
                    <p>{item.desc}</p>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className='relative'>
          <img
            src='/Images/background/service.webp'
            style={{ filter: "blur(10px)" }}
            alt='product'
            className='w-full h-80 object-cover'
          />
          <div className='absolute inset-0 flex items-center justify-start'>
            <div className='max-w-screen-xl mx-8 px-4 md:text-start md:px-8'>
              <div className='w-full space-y-3 md:mx-auto'>
                <h3 className='text-black bg-primary px-2 py-1 lg:px-4 lg:py-2 max-w-fit rounded-lg font-semibold text-sm lg:text-base'>
                  Professional services
                </h3>
                <p className='text-black text-xl font-bold sm:text-3xl'>
                  Hydraulic Excellence, Reliable Performance
                </p>
                <p className='text-black text-sm lg:text-lg'>
                  With an experienced team of technicians and advanced
                  equipment, we ensure that every repair and maintenance is
                  carried out to the highest standards. Entrust your hydraulic
                  service needs to us and experience the difference.
                </p>
              </div>
              <div className='mt-4'>
                <a
                  href='https://wa.me/628129099299'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='inline-block py-2 px-4 text-white font-medium bg-green-500 duration-150 hover:bg-green-700 active:bg-green-900 rounded-lg shadow-md hover:shadow-none'>
                    <span className='flex flex-row items-center justify-center gap-2'>
                      <img
                        src='/Images/WHATSAPP-ICON.png'
                        alt='product'
                        className='w-8 object-contain'
                      />
                      <p className='text-sm'>Reach Us</p>
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
      <FloatingIcon />
    </div>
  );
};

export default Services;
