import React from "react";
import Navbar from "../../Components/Layouts/Navbar/navbar";
import Footer from "../../Components/Layouts/Footer/footer";
import Carousel from "../../Components/Layouts/Carousel/carousel";
import FloatingIcon from "../../Components/UI/Floating-Icon/floating-icon";
import "./home.css";

const Home = () => {
  const brand = [
    "kawasaki.png",
    "komatsu.png",
    "kyb.png",
    "mitsuboshi.jpg",
    "parker.png",
    "shimadzu.png",
  ];

  return (
    <div className='flex flex-col'>
      <Navbar />
      <div className=''>
        <Carousel title='Hydraulic Pump' subTitle='Catalogue' />
        <section className='w-full mx-auto md:w-4/5 py-8'>
          <div className='py-4'>
            <div className='max-w-screen-xl mx-auto px-4 md:px-8'>
              <div className='max-w-xl mx-auto text-center'>
                <h3 className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                  Our Brands
                </h3>
                <p className='text-gray-600 mt-3'>
                  Meet our valued partners who have entrusted their vision and
                  success to us.
                </p>
              </div>
              <div className='mt-12 flex justify-center'>
                <ul className='inline-grid grid-cols-2 gap-x-10 gap-y-6 md:gap-x-16 md:grid-cols-3 lg:grid-cols-4'>
                  {brand && brand.length > 0 ? (
                    brand.map((item, itemIndex) => (
                      <img
                        key={itemIndex}
                        className='rounded-lg aspect-square object-contain w-24 lg:w-36 self-center mx-auto'
                        src={"Images/brand/" + item}
                        loading='lazy'
                        alt='product'
                      />
                    ))
                  ) : (
                    <p>Load Failed</p>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <section className='flex flex-col place-items-center place-content-evenly space-y-4 mx-auto md:flex-row md:space-x-4 md:space-y-0 md:my-4 lg:space-x-5'>
            <div className='relative flex w-full h-32 lg:h-48 lg:w-96 xl:w-2/5 shadow-xl sm:rounded-xl sm:overflow-hidden'>
              <a href='/products' className='w-full h-full'>
                <img
                  src='/Images/background/product.jpg'
                  alt='product'
                  className='w-full h-full md:rounded-xl object-cover transition-transform duration-200 hover:scale-105'
                />
                <div className='absolute inset-0 md:rounded-xl bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300'>
                  <div className='text-white mx-4 lg:mx-8'>
                    <div className='text-2xl lg:text-4xl font-bold mb-2 text-center'>
                      PRODUCT
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className='relative flex w-full h-32 lg:h-48 lg:w-96 xl:w-2/5 shadow-xl sm:rounded-xl sm:overflow-hidden'>
              <a href='/facilities' className='w-full h-full'>
                <img
                  src='/Images/background/service-banner.jpg'
                  alt='product'
                  className='w-full h-full md:rounded-xl object-cover transition-transform duration-200 hover:scale-105'
                />
                <div className='absolute inset-0 md:rounded-xl bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300'>
                  <div className='text-white mx-4 lg:mx-8'>
                    <div className='text-2xl lg:text-4xl font-bold mb-2 text-center'>
                      FACILITIES
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </section>
        </section>
        <Footer />
      </div>
      <FloatingIcon />
    </div>
  );
};

export default Home;
