import React from "react";

const FloatingIcon = () => {
  return (
    <a href='https://wa.me/628129099299' target='_blank' rel='noreferrer'>
      <img
        src='/Images/WHATSAPP-ICON.png'
        alt='used product'
        className='w-12 sm:w-16 object-cover fixed bottom-8 right-8 cursor-pointer duration-150 hover:scale-110'
      />
    </a>
  );
};

export default FloatingIcon;
