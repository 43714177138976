export const assignCookie = (value: string) => {
  const expires = new Date();
  const days = 7;
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);

  document.cookie = `accessToken=${value};expires=${expires.toUTCString()};path=/`;
};

export const grabCookie = () => {
  const name = "accessToken=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      const token = c.substring(name.length, c.length);

      // Decode the JWT token to get the payload
      const payload = JSON.parse(atob(token.split(".")[1]));

      // Check if the token is expired
      const now = Math.floor(Date.now() / 1000); // current time in seconds
      if (payload.exp && payload.exp < now) {
        return null; // Token is expired
      }

      return token;
    }
  }
  return null;
};
