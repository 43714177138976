import React, { CSSProperties } from "react";
import DashboardSidebar from "../../Components/Layouts/DashboardSidebar/dashboardsidebar";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { API } from "../../API/API";
import { ConfirmationModal } from "../../Components/Layouts/ConfirmationBox/confirmationbox";
import MoonLoader from "react-spinners/MoonLoader";

interface BrandProps {
  name: string;
  id: number;
}

const DashboardBrand = () => {
  const [loading, setLoading] = React.useState(false);
  const [action, setAction] = React.useState("None");
  const [search, setSearch] = React.useState("");
  const [brand, setBrand] = React.useState<BrandProps[]>([]);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("");
  const [confirmCallback, setConfirmCallback] = React.useState<
    null | ((result: boolean) => void)
  >(null);

  const [currId, setCurrId] = React.useState(0);
  const [currName, setCurrName] = React.useState("");

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "white",
  };

  const getBrand = async () => {
    const response = await API.get("/brand");
    setBrand(response.data);
    try {
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
    }
  };

  const clearCurrForm = () => {
    setCurrName("");
    setCurrId(0);
  };

  React.useEffect(() => {
    getBrand();
  }, []);

  const handleAddClick = () => {
    setAction("Add");
  };

  //   const handleEditClick = (item: {
  //     id: number;
  //     name: string;
  //     images: string;
  //     type: { name: string; id: number };
  //     brand: { name: string; id: number };
  //   }) => {
  //     setAction("Edit");
  //     setCurrName(item.name);
  //     setCurrId(item.id);
  //   };

  const handleDeleteClick = (idx: number) => {
    setModalMessage("Are you sure you want to delete this product?");
    setModalOpen(true);

    setConfirmCallback(() => (result: boolean) => {
      setModalOpen(false);
      if (result) {
        setLoading(true);
        deleteBrand(idx);
      }
    });
  };

  const createBrand = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currName.trim() === "") {
      toast.error("Brand name can't be empty", {
        duration: 3000,
      });
      return;
    }

    if (currName.length > 100) {
      toast.error("Brand name too long (Max 100 Character)", {
        duration: 3000,
      });
      return;
    }

    setModalMessage("Are you sure you want to create this brand?");
    setModalOpen(true);

    setConfirmCallback(() => async (result: boolean) => {
      setModalOpen(false);
      if (result) {
        setLoading(true);
        try {
          const response = await API.post("brand", { name: currName });

          if (response.data.message) {
            toast.success(response.data.message, {
              duration: 3000,
            });
            clearCurrForm();
            setAction("None");

            setBrand([]);
            getBrand();
          }
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response) {
              toast.error(error.response.data.message, { duration: 3000 });
            } else {
              toast.error("An error occurred: " + error.message, {
                duration: 3000,
              });
            }
          } else {
            toast.error("An unknown error occurred", { duration: 3000 });
          }
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  //   const updateBrand = async (event: React.FormEvent<HTMLFormElement>) => {
  //     event.preventDefault();
  //     if (currName.trim() === "") {
  //       toast.error("Brand name can't be empty", {
  //         duration: 3000,
  //       });
  //       return;
  //     }

  //     if (currName.length > 100) {
  //       toast.error("Brand name too long (Max 100 Character)", {
  //         duration: 3000,
  //       });
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append("name", currName);

  //     setModalMessage("Are you sure you want to update this brand?");
  //     setModalOpen(true);

  //     setConfirmCallback(() => async (result: boolean) => {
  //       setModalOpen(false);
  //       if (result) {
  //         try {
  //           const response = await API.patch(`brand/${currId}`, formData, {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //           });

  //           if (response.data.message) {
  //             toast.success(response.data.message, {
  //               duration: 3000,
  //             });
  //             clearCurrForm();
  //             setAction("None");

  //             setBrand([]);
  //             getBrand();
  //           }
  //         } catch (error) {
  //           if (error instanceof AxiosError) {
  //             if (error.response) {
  //               toast.error(error.response.data.message, { duration: 3000 });
  //             } else {
  //               toast.error("An error occurred: " + error.message, {
  //                 duration: 3000,
  //               });
  //             }
  //           } else {
  //             toast.error("An unknown error occurred", { duration: 3000 });
  //           }
  //         }
  //       }
  //     });
  //   };

  const deleteBrand = async (id: number) => {
    try {
      const response = await API.delete(`brand/${id}`);
      if (response.data.message) {
        toast.success(response.data.message, {
          duration: 3000,
        });

        setBrand([]);
        getBrand();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='bg-white min-h-screen flex flex-row'>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className='my-20 w-20 mx-auto col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-5'>
            <MoonLoader
              loading={loading}
              cssOverride={override}
              data-testid='loader'
              speedMultiplier={0.5}
            />
          </div>
        </div>
      )}
      {isModalOpen && (
        <ConfirmationModal
          message={modalMessage}
          onConfirm={(result) => {
            if (confirmCallback) confirmCallback(result);
          }}
        />
      )}
      <DashboardSidebar />
      <div className='max-w-screen-xl mx-auto px-4 md:px-8 my-8'>
        <div className='items-start justify-between md:flex'>
          <div className='max-w-xl lg:max-w-2xl'>
            <h3
              className='text-gray-800 text-xl font-bold sm:text-2xl'
              id='topPage'
            >
              Brand Management
            </h3>
            <p className='text-gray-400 mt-2 text-xs lg:text-sm max-w-xl'>
              Brand management involves the development, implementation, and
              oversight of strategies to enhance the value and perception of a
              brand. This includes maintaining brand consistency, managing brand
              equity, and ensuring that the brand aligns with market needs and
              business objectives through strategic planning and execution.
            </p>
          </div>
          <div className='mt-3 md:mt-0'>
            <button
              onClick={() => {
                handleAddClick();
                clearCurrForm();
              }}
              className='inline-block px-4 py-2 text-primary duration-150 font-medium bg-green-500 hover:bg-green-600 active:bg-green-700 rounded-lg md:text-sm truncate'
            >
              Add Brand
            </button>
          </div>
        </div>

        {action === "Add" ? (
          <form className='mt-8' onSubmit={createBrand}>
            <div className='grid'>
              <div className='flex flex-row justify-between place-items-center bg-green-200 rounded-lg p-3'>
                <p className='text-xl font-semibold'>Add New Brand</p>
                <img
                  src='/Images/icons8-close-100.png'
                  alt='icon'
                  className='object-cover w-4 lg:w-6 cursor-pointer'
                  onClick={() => {
                    setAction("None");
                    clearCurrForm();
                  }}
                />
              </div>
              <p className='text-gray-400 mt-2 text-xs max-w-2xl lg:text-sm mb-4 mr-8'>
                Adding a new brand to our catalog involves a meticulous process
                to ensure each brand meets our quality standards and aligns with
                our company values. In this section, you will provide all
                necessary details about the new brand.
              </p>
              <div className='flex flex-row gap-2 mb-8 mx-auto'>
                <div className='flex flex-col gap-2 '>
                  <div className='w-full'>
                    <label
                      htmlFor='product_name'
                      className='block mb-2 text-sm font-medium text-gray-900'
                    >
                      Brand Name
                    </label>
                    <input
                      type='text'
                      id='product_name'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-60 p-2.5'
                      placeholder='Product Name'
                      value={currName}
                      onChange={(e) => setCurrName(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='text-white bg-green-500 hover:bg-green-600 active:bg-green-700 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center mx-auto px-20'
              >
                Add
              </button>
            </div>
          </form>
        ) : action === "Edit" ? (
          <div></div>
        ) : (
          //   <form className='mt-8' onSubmit={updateBrand}>
          //     <div className='grid'>
          //       <div className='flex flex-row justify-between place-items-center bg-yellow-100 rounded-lg p-3'>
          //         <p className='text-xl font-semibold'>Edit Product</p>
          //         <img
          //           src='/Images/icons8-close-100.png'
          //           alt='icon'
          //           className='object-cover w-4 lg:w-6 cursor-pointer'
          //           onClick={() => {
          //             setAction("None");
          //             clearCurrForm();
          //           }}
          //         />
          //       </div>

          //       <p className='text-gray-400 mt-2 text-xs mr-8 lg:text-sm mb-4 max-w-2xl'>
          //         Editing a brand allows you to update and refine the details of
          //         existing brands to ensure they remain relevant and appealing to
          //         our customers. In this section, you can modify the brand's name,
          //         category, logo, and any other pertinent information.
          //       </p>
          //       <div className='flex flex-row gap-2 mb-8 mx-auto'>
          //         <div className='flex flex-col gap-2 '>
          //           <div className='w-full'>
          //             <label
          //               htmlFor='product_name'
          //               className='block mb-2 text-sm font-medium text-gray-900'
          //             >
          //               Brand Name
          //             </label>
          //             <input
          //               type='text'
          //               id='product_name'
          //               className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5'
          //               placeholder='Product Name'
          //               value={currName}
          //               onChange={(e) => setCurrName(e.target.value)}
          //               required
          //             />
          //           </div>
          //         </div>
          //       </div>
          //       <button
          //         type='submit'
          //         className='text-white bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-600 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center mx-auto px-20'
          //       >
          //         Update
          //       </button>
          //     </div>
          //   </form>
          ""
        )}

        <div className='my-4 flex place-content-end'>
          <label
            htmlFor='default-search'
            className='mb-2 text-sm font-medium text-gray-900 sr-only'
          >
            Search
          </label>
          <div className='relative'>
            <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
              <svg
                className='w-4 h-4 text-gray-500'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 20 20'
              >
                <path
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                />
              </svg>
            </div>
            <input
              value={search}
              type='search'
              id='default-search'
              className='w-64 p-2.5 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50'
              placeholder='Search by Product Name'
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div
          className='my-4 shadow-sm border rounded-lg overflow-x-auto'
          style={{ maxHeight: "480px" }}
        >
          <table className='w-full table-auto overflow-g text-sm text-left'>
            <thead className='bg-gray-50 text-gray-600 font-medium border-b'>
              <tr>
                <th className='py-3 px-6 text-center'>Product Brand</th>

                <th className='py-3 px-6 text-center'>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 divide-y text-center'>
              {brand && brand.length > 0 ? (
                search === "" ? (
                  brand.map((item, idx) => (
                    <tr key={idx}>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {item.name}
                      </td>
                      <td className='text-center px-6 whitespace-nowrap'>
                        {/* <button
                          onClick={() => {
                            clearCurrForm();
                            handleEditClick(item);
                            scrollToTopPage();
                          }}
                          className='py-2 px-3 font-medium text-third hover:text-yellow-500 duration-150 hover:bg-gray-50 rounded-lg'
                        >
                          Edit
                        </button> 
                        |*/}
                        <button
                          onClick={() => handleDeleteClick(item.id)}
                          className='py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg'
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : brand.filter((item) =>
                    (item.name || "")
                      .toLowerCase()
                      .includes((search || "").toLowerCase())
                  ).length > 0 ? (
                  brand
                    .filter((item) =>
                      (item.name || "")
                        .toLowerCase()
                        .includes((search || "").toLowerCase())
                    )
                    .map((item, idx) => (
                      <tr key={idx}>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          {item.name}
                        </td>

                        <td className='text-center px-6 whitespace-nowrap'>
                          {/* <button
                            
                            onClick={() => {
                              clearCurrForm();
                              handleEditClick(item);
                              scrollToTopPage();
                            }}
                            className='py-2 px-3 font-medium text-third hover:text-yellow-500 duration-150 hover:bg-gray-50 rounded-lg'
                          >
                            Edit
                          </button>
                          | */}
                          <button
                            onClick={() => handleDeleteClick(item.id)}
                            className='py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg'
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td className='px-6 py-4 whitespace-nowrap'>No Data</td>
                    <td className='text-center px-6 whitespace-nowrap'>
                      No Data
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td className='px-6 py-4 whitespace-nowrap'>No Data</td>
                  <td className='text-center px-6 whitespace-nowrap'>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
            <thead className='bg-gray-50 text-gray-600 font-medium border-b'>
              <tr>
                <th className='py-3 px-6 text-center'>Product Brand</th>
                <th className='py-3 px-6 text-center'>Action</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardBrand;
