import React from "react";

const MapComponent: React.FC = () => {
  return (
    <iframe
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.92598045620537!2d106.82997603722053!3d-6.155434595208505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f50e95f496b5%3A0xe032469e4c808135!2sMAJU%20JAYA%20HYDRAULIC!5e0!3m2!1sid!2sid!4v1723648610854!5m2!1sid!2sid'
      loading='lazy'
      style={{ height: "100%", width: "100%" }}
    ></iframe>
  );
};

export default MapComponent;
