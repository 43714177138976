import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./navbar.css";

const navigation = [
  { name: "Home", href: "/", current: false },
  { name: "About", href: "/about", current: false },
  { name: "Facilities", href: "/facilities", current: false },
  { name: "Products", href: "/products", current: false },
  { name: "Contact", href: "/contact", current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const location = useLocation();

  const url = window.location.href;
  const parts = url.split("/");
  const lastPart = parts.pop() || parts.pop();

  const updatedNavigation = navigation.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Disclosure as='nav' className='bg-white z-50'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 right-0 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='relative inline-flex items-center justify-center rounded-md p-2 text-black hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-start sm:items-stretch sm:justify-between'>
                <div className='flex flex-shrink-0 ml-2 sm:ml-0 items-center'>
                  <img
                    className='h-8 sm:h-10 w-auto'
                    src='/Images/horizontal-logo.png'
                    alt='Logo'
                  />
                </div>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {updatedNavigation.map((item) =>
                      item.name === "Facilities" ? (
                        <div key={item.name}>
                          <div className=' flex flex-row place-items-center underline-animation'>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current ? "text-red-500" : "text-black",
                                "nav-link"
                              )}
                            >
                              Facilities
                            </a>
                            <div
                              className='cursor-pointer'
                              onClick={toggleDropdown}
                            >
                              <svg
                                className='w-2.5 h-2.5 ms-2.5'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 10 6'
                              >
                                <path
                                  stroke='currentColor'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='m1 1 4 4 4-4'
                                />
                              </svg>
                            </div>
                          </div>

                          <div
                            className={`${
                              isDropdownOpen ? "block" : "hidden"
                            } z-10 font-normal absolute top-16 bg-white border-2 divide-y divide-gray-100 rounded-sm shadow w-44`}
                          >
                            <ul className='py-2 text-sm text-gray-700'>
                              <li>
                                <a
                                  href='/service'
                                  className={classNames(
                                    lastPart === "service"
                                      ? "text-red-500"
                                      : "text-black",
                                    "nav-link underline-animation block px-4 py-2 hover:bg-gray-200"
                                  )}
                                >
                                  Service
                                </a>
                              </li>
                              <li>
                                <a
                                  href='/assembly'
                                  className={classNames(
                                    lastPart === "assembly"
                                      ? "text-red-500"
                                      : "text-black",
                                    "nav-link underline-animation block px-4 py-2 hover:bg-gray-200"
                                  )}
                                >
                                  Assembly
                                </a>
                              </li>
                              <li>
                                <a
                                  href='/test'
                                  className={classNames(
                                    lastPart === "test"
                                      ? "text-red-500"
                                      : "text-black",
                                    "nav-link underline-animation block px-4 py-2 hover:bg-gray-200"
                                  )}
                                >
                                  Test Banch
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "text-red-500" : "text-black",
                            "nav-link underline-animation"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'></div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pb-3 pt-2'>
              {updatedNavigation.map((item) =>
                item.name === "Facilities" ? (
                  <div key={item.name} className=''>
                    <div className=' flex flex-row place-items-center'>
                      <a
                        href={item.href}
                        className={classNames(
                          item.current ? "text-red-500" : "text-black",
                          "block rounded-md px-3 py-2 text-base font-medium underline-animation"
                        )}
                      >
                        Facilities
                      </a>
                      <div
                        className='cursor-pointer mt-1'
                        onClick={toggleDropdown}
                      >
                        <svg
                          className='w-2.5 h-2.5 ms-2.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 10 6'
                        >
                          <path
                            stroke='currentColor'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='m1 1 4 4 4-4'
                          />
                        </svg>
                      </div>
                    </div>

                    <div
                      className={`${
                        isDropdownOpen ? "block" : "hidden"
                      } z-10 font-normal absolute top-52 mx-2 w-60 bg-white border-2 divide-y divide-gray-100 rounded-lg shadow`}
                    >
                      <Disclosure.Button
                        key={"service"}
                        as='a'
                        href='/service'
                        className={classNames(
                          lastPart === "service"
                            ? "text-red-500"
                            : "text-black",
                          "block rounded-md px-3 py-2 text-base font-medium underline-animation"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        Service
                      </Disclosure.Button>
                      <Disclosure.Button
                        key={"assembly"}
                        as='a'
                        href='/assembly'
                        className={classNames(
                          lastPart === "assembly"
                            ? "text-red-500"
                            : "text-black",
                          "block rounded-md px-3 py-2 text-base font-medium underline-animation"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        Assembly
                      </Disclosure.Button>
                      <Disclosure.Button
                        key={"test"}
                        as='a'
                        href='/test'
                        className={classNames(
                          lastPart === "test" ? "text-red-500" : "text-black",
                          "block rounded-md px-3 py-2 text-base font-medium underline-animation"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        Test Banch
                      </Disclosure.Button>
                    </div>
                  </div>
                ) : (
                  <Disclosure.Button
                    key={item.name}
                    as='a'
                    href={item.href}
                    className={classNames(
                      item.current ? "text-red-500" : "text-black",
                      "block rounded-md px-3 py-2 text-base font-medium underline-animation"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                )
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
