import React from "react";
import FloatingIcon from "../../../Components/UI/Floating-Icon/floating-icon";
import Footer from "../../../Components/Layouts/Footer/footer";
import Navbar from "../../../Components/Layouts/Navbar/navbar";
import Banner from "../../../Components/Layouts/Banner/banner";

const Service = () => {
  return (
    <div className='flex flex-col'>
      <Navbar />
      <div className=''>
        <Banner
          title={"Service Pump and Motor"}
          image='service-motor-and-pump.jpeg'
        />
        <div className='sm:m-2'>
          <div className='grid gap-2 p-2 sm:gap-4 lg:p-4'>
            <div className='relative'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/service/service 1.jpg"}
                alt='service 1'
              />
            </div>
            <div className='flex flex-row gap-2 sm:gap-4'>
              <div className='relative'>
                <img
                  className='h-full w-full object-cover rounded-lg'
                  src={"Images/service/service 2.jpg"}
                  alt='service 2'
                />
              </div>
              <div className='relative'>
                <img
                  className='h-full w-full object-cover rounded-lg'
                  src={"Images/service/service 3.jpg"}
                  alt='service 3'
                />
              </div>
              <div className='relative'>
                <img
                  className='h-full w-full object-cover rounded-lg'
                  src={"Images/service/service 4.jpg"}
                  alt='service 4'
                />
              </div>
            </div>
            <div className='relative'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/service/service 5.jpg"}
                alt='service 1'
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <FloatingIcon />
    </div>
  );
};

export default Service;
