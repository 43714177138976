import React from "react";
import Divider from "../../UI/Divider/divider";
import { API } from "../../../API/API";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import Cookies from "js-cookie";
import { grabCookie } from "../../../Hooks/assignCookie";

const DashboardSidebar = () => {
  const navigation = [
    {
      href: "product",
      name: "Product Management",
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-5 h-5'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122'
          />
        </svg>
      ),
    },
    {
      href: "brand",
      name: "Brand Management",
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-5 h-5'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122'
          />
        </svg>
      ),
    },
  ];

  const url = window.location.href;
  const parts = url.split("/");
  const lastPart = parts.pop() || parts.pop();
  const navigate = useNavigate();

  function svgLogout() {
    return (
      <svg
        className='flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white'
        aria-hidden='true'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 18 16'
      >
        <path
          stroke='currentColor'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2'
          d='M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3'
        />
      </svg>
    );
  }

  const logout = async () => {
    try {
      const response = await API.post("user/logout");
      if (response.data.message === "User logged out successfully") {
        Cookies.remove("accessToken");
        navigate("/u/login");
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
    }
  };

  React.useEffect(() => {
    isLogin();
  }, []);

  const isLogin = async () => {
    const currSession = grabCookie();

    if (!currSession) {
      navigate("/u/login");
    } else {
      try {
        const response = await API.post("user", {
          token: currSession,
        });

        if (!response.data.token) {
          navigate("/u/login");
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response) {
            toast.error(error.response.data.message, { duration: 3000 });
            navigate("/u/login");
          } else {
            toast.error("An error occurred: " + error.message, {
              duration: 3000,
            });
          }
        } else {
          toast.error("An unknown error occurred", { duration: 3000 });
        }
      }
    }
  };

  return (
    <aside className='bg-primary w-fit xl:w-80 h-min-full border-r'>
      <div className='flex flex-col h-full'>
        <nav className='px-6 py-4'>
          <div>
            <span className='hidden lg:block text-xl text-center font-semibold my-4'>
              Dashboard Admin
            </span>
            <Divider />
            <ul className='space-y-2'>
              {navigation.map((item, idx) => (
                <li key={idx}>
                  <a
                    href={`/u/${item.href}`}
                    className={`flex items-center gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-gray-200 active:bg-gray-300 duration-150 truncate ${
                      lastPart === item.href ? "bg-gray-200" : "bg-primary"
                    }`}
                  >
                    {item.icon ? (
                      <div className='text-gray-500'>{item.icon}</div>
                    ) : (
                      ""
                    )}
                    <span className='hidden lg:block'>{item.name}</span>
                  </a>
                </li>
              ))}
            </ul>
            <Divider />
            <ul className='space-y-2'>
              <li>
                <button
                  onClick={logout}
                  className='flex items-center w-full gap-x-2 text-gray-600 p-2 rounded-lg hover:bg-gray-200 active:bg-gray-300 duration-150 bg-primary'
                >
                  {svgLogout() ? (
                    <div className='text-gray-500'>{svgLogout()}</div>
                  ) : (
                    ""
                  )}
                  <span className='hidden lg:block'>Log out</span>
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default DashboardSidebar;
