import React from "react";
import Navbar from "../../Components/Layouts/Navbar/navbar";
import FloatingIcon from "../../Components/UI/Floating-Icon/floating-icon";
import Banner from "../../Components/Layouts/Banner/banner";
import "./contact.css";
import MapComponent from "../../Components/Layouts/MapComponent/MapComponent";

const Contact = () => {
  return (
    <div className='flex flex-col'>
      <Navbar />
      <div className=''>
        <Banner title='Contact Us' image='contact.jpg' />
        <section className='w-full md:w-4/5 py-8 my-4 sm:my-12 h-full mx-auto'>
          <div className='grid grid-cols-1 mx-12 sm:mx-0 sm:grid-cols-3 sm:gap-8'>
            <img
              src='/Images/vertical-logo.png'
              alt='logo'
              className='object-contain w-60 xl:w-72 aspect-square mb-4 mx-auto sm:place-items-start sm:align-top sm:mx-0 sm:mb-0'
            />
            <div className='mx-2'>
              <p className='font-semibold text-xl my-2 sm:my-0 sm:mb-2 xl:text-2xl'>
                PT Maju Jaya Arkananta
              </p>
              <p className='font-semibold text-sm lg:text-lg xl:text-xl'>
                Head Office
              </p>
              <p className='text-gray-600 text-sm mb-1 xl:text-lg lg:mb-2'>
                28B Karang Anyar Street, Central Jakarta, Jakarta 10740
              </p>
              <p className='font-semibold text-sm lg:text-lg xl:text-xl'>
                Phone
              </p>
              <p className='text-gray-600 text-sm mb-1 xl:text-lg lg:mb-2'>
                021-22681872
              </p>
              <p className='font-semibold text-sm lg:text-lg xl:text-xl'>
                Email Address
              </p>
              <p className='text-gray-600 text-sm xl:text-lg'>
                majujaya@hydraulicpump.co.id
              </p>
            </div>
            <div className='mx-2'>
              <p className='font-semibold text-xl my-2 sm:my-0 sm:mb-2 xl:text-2xl'>
                Social Media
              </p>
              <a
                href='https://www.instagram.com/hydraulicpump.co.id?igsh=NTV5YnBvN20zNzhi'
                target='_blank'
                rel='noreferrer'
                className='hover:underline'
              >
                <span className='flex flex-row items-center justify-start gap-1 lg:mb-1'>
                  <img
                    src='/Images/icons8-instagram-96.png'
                    alt='icon'
                    className='object-cover w-4 md:w-5 lg:w-6'
                  />

                  <p className='text-gray-600 mb-1 xl:text-lg mx-1'>
                    Instagram
                  </p>
                </span>
              </a>
              <a
                href='https://web.facebook.com/profile.php?id=61564493131177'
                target='_blank'
                rel='noreferrer'
                className='hover:underline'
              >
                <span className='flex flex-row items-center justify-start gap-1 lg:mb-1'>
                  <img
                    src='/Images/icons8-facebook-circled-96.png'
                    alt='icon'
                    className='object-cover w-4 md:w-5 lg:w-6'
                  />

                  <p className='text-gray-600 mb-1 xl:text-lg cursor-pointer mx-1'>
                    Facebook
                  </p>
                </span>
              </a>
              <a
                href='https://www.tiktok.com/@hydraulicpump.co.id'
                target='_blank'
                rel='noreferrer'
                className=' hover:underline'
              >
                <span className='flex flex-row items-center justify-start gap-1 lg:mb-1'>
                  <img
                    src='/Images/icons8-tiktok-100.png'
                    alt='icon'
                    className='object-cover w-4 md:w-5 lg:w-6'
                  />

                  <p className='text-gray-600 mb-1 xl:text-lg mx-1'>TikTok</p>
                </span>
              </a>
            </div>
          </div>
          <div className='h-80 my-12'>
            <p className='text-center text-2xl sm:text-3xl lg:text-4xl font-semibold my-8'>
              Our Location
            </p>
            <MapComponent />
          </div>
        </section>
      </div>
      <FloatingIcon />
    </div>
  );
};

export default Contact;
