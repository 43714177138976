import React from "react";
import Navbar from "../../Components/Layouts/Navbar/navbar";
import Footer from "../../Components/Layouts/Footer/footer";
import FloatingIcon from "../../Components/UI/Floating-Icon/floating-icon";
import Banner from "../../Components/Layouts/Banner/banner";
import "./about.css";

const About = () => {
  return (
    <div className='flex flex-col'>
      <Navbar />
      <div className=''>
        <Banner title='About Us' image='about.jpg' />
        <section className='py-8'>
          <div className='max-w-screen-lg mx-auto md:px-8'>
            <div className='items-start gap-x-12 sm:px-4 md:px-0 lg:flex mx-8 flex flex-col sm:py-8 px-4 space-y-3 mt-6  md:mt-0 '>
              {/* <h3 className='text-red-500 font-semibold'>Hydraulic Pump</h3> */}
              <p className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                PT Maju Jaya Arkananta
              </p>
              <p className='mt-3 text-gray-600 text-justify'>
                Maju Jaya was founded in 1993, specializing in all kinds of
                hydraulic pump from various brands. Starting from just a small
                shop in West Jakarta, we now became one of the most reputable
                hydraulic pump solutions in Indonesia. Over the years, we are
                expanding our services not only on sales, but also repairing and
                providing pressure test for our customers all over the country.
                <br />
                <br />
                We have completed projects in various lines of businesses, such
                as mining, palm oil plantation, miscellaneous infrastructure
                projects, etc. Striving to be the one stop solution for the
                customer needs, today we offer extended service such as lathe
                and milling work as well.
                <br />
                <br />
                We earn the reputation from delivering robust and precise work,
                while in the other hand, offering flexibility solutions to meet
                the customer needs. We believe that our client satisfaction have
                always been the backbone of our business. Therefore, maintaining
                the relationship with the customers will always be our top
                priority.
              </p>

              {/* <div className='flex-1 mt-4 lg:mt-0 lg:block h-full w-full object-cover flex place-content-center'>
                <img
                  src='Images/background/sub-about.jpg'
                  className='sm:max-w-sm rounded-lg'
                  alt=''
                />
              </div> */}
            </div>
          </div>
        </section>

        <Footer />
      </div>
      <FloatingIcon />
    </div>
  );
};

export default About;
