import React from "react";

interface BannerProps {
  title: string;
  image: string;
}

const Banner: React.FC<BannerProps> = ({ title, image }) => {
  return (
    <div className='relative mx-auto'>
      <div className='overflow-hidden'>
        <div className='flex h-52 md:h-72 lg:h-80 xl:h-96'>
          <img
            src={"Images/background/" + image}
            alt={`banner`}
            className='w-full flex-shrink-0 object-cover'
          />
        </div>
        <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center place-items-center'>
          <p className='text-4xl sm:text-5xl xl:text-6xl font-bold text-primary text-wrap mx-20 text-center'>
            {title}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
