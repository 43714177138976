import React, { useState } from "react";
import toast from "react-hot-toast";
import { API } from "../../API/API";
import { useNavigate } from "react-router";
import { AxiosError } from "axios";
import { assignCookie, grabCookie } from "../../Hooks/assignCookie";
import Cookies from "js-cookie";

const Login = () => {
  const [isPasswordHidden, setPasswordHidden] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    isLogin();
  }, []);

  const isLogin = async () => {
    const currSession = grabCookie();
    if (currSession !== null) {
      try {
        const response = await API.post("user", {
          token: currSession,
        });

        if (response.data.token) {
          navigate("/u/product");
        } else {
          Cookies.remove("accessToken");
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response) {
            toast.error("Login failed. Please try again", { duration: 3000 });
          } else {
            toast.error("An error occurred: " + error.message, {
              duration: 3000,
            });
          }
        } else {
          toast.error("An unknown error occurred", { duration: 3000 });
        }
      }
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const login = async () => {
    if (email === "" || password === "")
      return toast.error("Fields Should Not Empty!", { duration: 3000 });

    if (!validateEmail(email))
      return toast.error("Wrong Email Format!", { duration: 3000 });

    try {
      const response = await API.post("user/login", {
        email,
        password,
      });

      if (response.data.message === "User logged in successfully") {
        assignCookie(response.data.token);
        navigate("/u/product");
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
    }
  };

  const handleLoginEnter = (e: any) => {
    if (e.key === "Enter") {
      login();
    }
  };

  return (
    <div className='bg-primary min-h-screen'>
      <div className='bg-white w-80 sm:w-96 border-secondary border rounded-xl p-8 h-auto mx-auto top-20 relative'>
        <img
          className='relative rounded-t-lg aspect-square object-contain w-60 h-32 mx-auto'
          src='../Images/horizontal-logo.png'
          alt=''
          loading='lazy'
        />
        <p className='text-3xl my-4 text-center font-medium'>Login</p>
        <div className='relative max-w-xs'>
          <label className='text-gray-600 font-medium'>Email</label>
          <input
            type='email'
            placeholder='Enter your email'
            onKeyDown={handleLoginEnter}
            onChange={(e) => setEmail(e.target.value)}
            className='mt-2 w-full pl-3 pr-3 py-3 text-gray-500 bg-transparent outline-none border focus:border-gray-500 shadow-sm rounded-lg'
          />
        </div>
        <div className='my-4'>
          <label className='text-gray-600 font-medium'>Password</label>
          <div className='mt-2 relative max-w-xs'>
            <input
              type={isPasswordHidden ? "password" : "text"}
              onKeyDown={handleLoginEnter}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Enter your password'
              className='w-full pr-12 pl-3 py-3 text-gray-500 bg-transparent outline-none border focus:border-gray-500 shadow-sm rounded-lg'
            />
            <button
              className='text-gray-400 absolute right-3 inset-y-0 my-auto active:text-gray-600'
              onClick={() => setPasswordHidden(!isPasswordHidden)}
            >
              {isPasswordHidden ? (
                <svg
                  className='w-6 h-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className='flex place-content-center mt-8'>
          <button
            onClick={() => login()}
            className='px-8 py-2 w-40 text-secondary border rounded-lg duration-100 hover:border-secondary active:shadow-lg'
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
