import React from "react";
import Navbar from "../../Components/Layouts/Navbar/navbar";
import Footer from "../../Components/Layouts/Footer/footer";
import FloatingIcon from "../../Components/UI/Floating-Icon/floating-icon";
import Banner from "../../Components/Layouts/Banner/banner";
import "./products.css";
import ProductLayout from "../../Components/Layouts/ProductLayout/productlayout";

const Products = () => {
  return (
    <div className='flex flex-col'>
      <Navbar />
      <div className=''>
        <Banner title='Explore Our Product' image='product.jpg' />
        <section className='w-full h-full mx-auto flex flex-col sm:flex-row gap-4'>
          <ProductLayout />
        </section>
        <Footer />
      </div>
      <FloatingIcon />
    </div>
  );
};

export default Products;
