import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className='bg-gray-200 p-2 sm:py-4 lg:py-8 text-black items-end'>
      <div className='mx-auto w-4/5 container'>
        <div className='grid sm:grid-cols-2'>
          <div className='hidden sm:block'>
            <img
              src='/Images/vertical-logo.png'
              alt='used product'
              className='w-32 h-16 rounded-xl sm:w-64 sm:h-32 lg:w-80 lg:h-40 object-contain'
            />
          </div>
          <div className='grid grid-cols-2 gap-4 w-full'>
            <div>
              <p className='navigation-title text-center'>
                PT Maju Jaya Arkananta
              </p>
              <div className='navigation-list text-start'>
                <span className='flex flex-row items-start justify-start gap-2 lg:gap-4'>
                  <img
                    src='/Images/icons8-address-100.png'
                    alt='icon'
                    className='object-cover w-4 md:w-5 lg:w-6'
                  />
                  <p className='text-wrap'>
                    28B Karang Anyar Street, Central Jakarta, Jakarta 10740
                  </p>
                </span>
                <span className='flex flex-row items-center justify-start gap-2 lg:gap-4'>
                  <img
                    src='/Images/icons8-phone-100.png'
                    alt='icon'
                    className='object-cover w-4 md:w-5 lg:w-6'
                  />
                  <p>021-22681872</p>
                </span>
                <span className='flex flex-row items-center justify-start gap-2 lg:gap-4'>
                  <img
                    src='/Images/icons8-email-100.png'
                    alt='icon'
                    className='object-cover w-4 md:w-5 lg:w-6'
                  />
                  <p>majujaya@hydraulicpump.co.id</p>
                </span>
              </div>
            </div>
            <div>
              <p className='navigation-title text-center'>Social Media</p>
              <div className='flex flex-row place-content-center gap-2 text-center'>
                <a
                  href='https://www.instagram.com/hydraulicpump.co.id?igsh=NTV5YnBvN20zNzhi'
                  target='_blank'
                >
                  <img
                    src='/Images/icons8-instagram-96.png'
                    alt='icon'
                    className='object-cover w-8 lg:w-12 hover:scale-110 transition-all duration-300 cursor-pointer'
                  />
                </a>
                <a
                  href='https://web.facebook.com/profile.php?id=61564493131177'
                  target='_blank'
                >
                  <img
                    src='/Images/icons8-facebook-circled-96.png'
                    alt='icon'
                    className='object-cover w-8 lg:w-12 hover:scale-110 transition-all duration-300 cursor-pointer'
                  />
                </a>
                <a
                  href='https://www.tiktok.com/@hydraulicpump.co.id'
                  target='_blank'
                >
                  <img
                    src='/Images/icons8-tiktok-100.png'
                    alt='icon'
                    className='object-cover w-8 lg:w-12 hover:scale-110 transition-all duration-300 cursor-pointer'
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <p className='copyright'>
          © {new Date().getFullYear()} PT MAJU JAYA ARKANANTA. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
