import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Home from "./Pages/Home/home";
import About from "./Pages/About/about";
import Services from "./Pages/Services/services";
import Contact from "./Pages/Contact/contact";
import Products from "./Pages/Products/products";
import Login from "./Pages/Login/login";
import DashboardProduct from "./Pages/DashboardProduct/dashboardproduct";
import Service from "./Pages/Services/ServiceSection/service";
import Assembly from "./Pages/Services/ServiceSection/assembly";
import Test from "./Pages/Services/ServiceSection/test";
import DashboardBrand from "./Pages/DashboardBrand/dashboardbrand";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/facilities' element={<Services />} />
        <Route path='/service' element={<Service />} />
        <Route path='/assembly' element={<Assembly />} />
        <Route path='/test' element={<Test />} />
        <Route path='/products' element={<Products />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/u/login' element={<Login />} />
        <Route path='/u/product' element={<DashboardProduct />} />
        <Route path='/u/brand' element={<DashboardBrand />} />
      </Routes>
      <Toaster
        position='bottom-left'
        toastOptions={{
          duration: 4000,
          style: {
            padding: "16px 40px",
          },
          success: {
            style: {
              background: "1px solid #005B41",
            },
          },
          error: {
            style: {
              border: "1px solid #BE3144",
            },
          },
        }}
      />
    </Router>
  );
}

export default App;
