import React from "react";
import FloatingIcon from "../../../Components/UI/Floating-Icon/floating-icon";
import Footer from "../../../Components/Layouts/Footer/footer";
import Navbar from "../../../Components/Layouts/Navbar/navbar";
import Banner from "../../../Components/Layouts/Banner/banner";

const Test = () => {
  return (
    <div className='flex flex-col'>
      <Navbar />
      <div className=''>
        <Banner title={"Test Banch"} image='test-banch.jpg' />
        <div className='sm:m-2'>
          <div className='flex flex-col sm:flex-row p-4 lg:p-8'>
            <div className='relative m-2'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/test/test 1.jpg"}
                alt='test 1'
              />
            </div>
            <div className='relative m-2'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/test/test 2.jpg"}
                alt='test 1'
              />
            </div>
            <div className='relative m-2'>
              <img
                className='h-full w-full object-cover rounded-lg'
                src={"Images/test/test 3.jpg"}
                alt='test 1'
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <FloatingIcon />
    </div>
  );
};

export default Test;
