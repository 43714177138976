import React, { CSSProperties } from "react";
import "./productcard.css";
import MoonLoader from "react-spinners/MoonLoader";
import Divider from "../../UI/Divider/divider";

interface ProductCardProps {
  data: {
    name: string;
    images: string;
    type: { name: string };
    brand: { name: string };
  }[];
  filter: string;
  loading: boolean;
}

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};

const ProductCard: React.FC<ProductCardProps> = ({ data, filter, loading }) => {
  const [search, setSearch] = React.useState("");
  const [allLoading, setAllLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(false);

  const [maxRow, setMaxRow] = React.useState(0);
  const [fetchRow, setFetchRow] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const loader = () => {
    setAllLoading(true);
    const timer = setTimeout(() => {
      setAllLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  };

  const paginationLoader = () => {
    setPageLoading(true);
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  };

  const maxRowInitial = () => {
    let page = 0;
    if (search === "") {
      page =
        filter === "All Product"
          ? data.length
          : data.filter((brand) => brand.brand.name === filter).length;
    } else {
      page =
        filter === "All Product"
          ? data.filter((item) =>
              (item.name || "")
                .toLowerCase()
                .includes((search || "").toLowerCase())
            ).length
          : data.filter((brand) => brand.brand.name === filter).length;
    }
    setMaxRow(page);
  };

  React.useEffect(() => {
    maxRowInitial();
  }, [data]);

  React.useEffect(() => {
    setFetchRow(0);
    maxRowInitial();
    loader();
  }, [search]);

  React.useEffect(() => {
    maxRowInitial();

    if (filter !== "All Product") {
      setSearch("");
      setFetchRow(0);
    } else if (search !== "") {
      setFetchRow(0);
    }
    loader();
  }, [filter]);

  const onClickPrevious = () => {
    // Hitung nilai baru fetchRow
    const newFetchRow = fetchRow - limit;

    // Jika nilai baru kurang dari 0, setel fetchRow ke 0
    if (newFetchRow < 0) {
      setFetchRow(0);
      paginationLoader();
    } else {
      setFetchRow(newFetchRow);
      paginationLoader();
    }
  };

  const onClickNext = () => {
    // Hitung nilai baru fetchRow
    const newFetchRow = fetchRow + limit;

    // Jika nilai baru melebihi maxRow, setel fetchRow ke maxRow
    if (newFetchRow > maxRow) {
      setFetchRow(maxRow); // Set fetchRow ke maxRow jika sudah melebihi
      paginationLoader();
    } else {
      setFetchRow(newFetchRow); // Set fetchRow ke newFetchRow jika tidak melebihi
      paginationLoader();
    }
  };

  return (
    <section className='w-full mx-auto px-4 lg:px-8'>
      <div className='my-8 sm:my-0'>
        <div className='flex flex-col sm:flex-row justify-start sm:justify-between place-items-center'>
          <p className='font-bold text-2xl sm:text-3xl xl:text-4xl text-start'>
            {filter.toUpperCase()}
          </p>
          <div
            className={`max-w-xs my-4 sm:my-0 self-end ${
              filter === "All Product" ? "block" : "hidden"
            }`}
          >
            <label
              htmlFor='default-search'
              className='mb-2 text-sm font-medium text-gray-900 sr-only'
            >
              Search
            </label>
            <div className='relative'>
              <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
                <svg
                  className='w-4 h-4 text-gray-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 20 20'
                >
                  <path
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                  />
                </svg>
              </div>
              <input
                value={search}
                type='search'
                id='default-search'
                className='block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50'
                placeholder='Search Product'
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='sm:my-8 lg:my-12'></div>
        {loading || pageLoading ? (
          <div className='my-20 w-20 mx-auto'>
            <MoonLoader
              loading={loading || pageLoading}
              cssOverride={override}
              data-testid='loader'
              speedMultiplier={0.5}
            />
          </div>
        ) : filter === "All Product" ? (
          <section className='w-full my-4 grid grid-cols-2 sm:grid-cols-3 gap-4 lg:grid-cols-4 xl:grid-cols-5 sm:my-8 xl:gap-8'>
            {search === "" ? (
              data.length > 0 ? (
                data
                  .slice(fetchRow, fetchRow + limit)
                  .map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className='relative max-w-lg bg-white border border-gray-200 rounded-lg shadow hover:scale-105 duration-300 overflow-hidden'
                    >
                      <img
                        className='relative rounded-t-lg aspect-square object-cover w-full'
                        src={item.images}
                        alt=''
                        loading='lazy'
                      />
                      <Divider />
                      <div className='relative p-2 py-2'>
                        <p className='text-base lg:text-lg font-base tracking-tight text-gray-800 text-center'>
                          {item.name}
                        </p>
                      </div>
                    </div>
                  ))
              ) : (
                <div className='w-full text-center col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-5 my-4'>
                  <p className='text-xl font-semibold text-gray-600'>
                    No Product Found
                  </p>
                </div>
              )
            ) : allLoading || pageLoading ? (
              <div className='my-20 w-20 mx-auto col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-5'>
                <MoonLoader
                  loading={allLoading || pageLoading}
                  cssOverride={override}
                  data-testid='loader'
                  speedMultiplier={0.5}
                />
              </div>
            ) : data.filter((item) =>
                (item.name || "")
                  .toLowerCase()
                  .includes((search || "").toLowerCase())
              ).length > 0 ? (
              data.length > 0 ? (
                data
                  .filter((item) =>
                    (item.name || "")
                      .toLowerCase()
                      .includes((search || "").toLowerCase())
                  )
                  .slice(fetchRow, fetchRow + limit)
                  .map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className='relative max-w-lg bg-white border border-gray-200 rounded-lg shadow hover:scale-105 duration-300 overflow-hidden'
                    >
                      <img
                        className='relative rounded-t-lg aspect-square object-cover w-full'
                        src={item.images}
                        alt=''
                        loading='lazy'
                      />
                      <Divider />
                      <div className='relative p-2 py-2'>
                        <p className='text-base lg:text-lg font-base tracking-tight text-gray-800 text-center'>
                          {item.name}
                        </p>
                      </div>
                    </div>
                  ))
              ) : (
                <div className='w-full text-center col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-5 my-4'>
                  <p className='text-xl font-semibold text-gray-600'>
                    No Product Found
                  </p>
                </div>
              )
            ) : (
              <div className='w-full text-center col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-5 my-4'>
                <p className='text-xl font-semibold text-gray-600'>
                  No Product Found
                </p>
              </div>
            )}
          </section>
        ) : (
          <section className='w-full my-4 grid grid-cols-2 sm:grid-cols-3 gap-4 lg:grid-cols-4 xl:grid-cols-5 sm:my-8 xl:gap-8'>
            {data.filter((brand) => brand.brand.name === filter).length > 0 ? (
              data
                .filter((brand) => brand.brand.name === filter)
                .slice(fetchRow, fetchRow + limit)
                .map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className='relative max-w-lg bg-white border border-gray-200 rounded-lg shadow hover:scale-105 duration-300 overflow-hidden'
                  >
                    <img
                      className='relative rounded-t-lg aspect-square object-cover w-full'
                      src={item.images}
                      alt=''
                      loading='lazy'
                    />
                    <Divider />
                    <div className='relative p-2 py-2'>
                      <p className='text-base lg:text-lg font-base tracking-tight text-gray-800 text-center'>
                        {item.name}
                      </p>
                    </div>
                  </div>
                ))
            ) : (
              <div className='w-full text-center col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-5 my-4'>
                <p className='text-xl font-semibold text-gray-600'>
                  No Product Found
                </p>
              </div>
            )}
          </section>
        )}
      </div>
      {loading || pageLoading || allLoading || maxRow === 0 ? (
        ""
      ) : (
        <nav
          aria-label='Page navigation example'
          className='flex w-full place-items-center place-content-center'
        >
          <ul className='inline-flex -space-x-px text-base sm:text-lg '>
            <li>
              <button
                onClick={() => onClickPrevious()}
                disabled={fetchRow <= 0 ? true : false}
                className='flex items-center justify-center px-6 h-12 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700'
              >
                Previous
              </button>
            </li>
            <li>
              <button
                disabled
                className='flex items-center justify-center px-6 h-12 leading-tight text-gray-500 bg-white border border-gray-300'
              >
                {Math.min(fetchRow + 1, maxRow)} -{" "}
                {Math.min(fetchRow + limit, maxRow)} / {maxRow}
              </button>
            </li>
            <li>
              <button
                onClick={() => onClickNext()}
                disabled={fetchRow + limit >= maxRow}
                className='flex items-center justify-center px-6 h-12 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700'
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </section>
  );
};

export default ProductCard;
