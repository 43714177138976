import React, { useState, useEffect } from "react";

const images = ["home 1.png", "home 2.png", "home 3.png"];

interface CarouselProps {
  title: string;
  subTitle: string;
}

const Carousel: React.FC<CarouselProps> = ({ title, subTitle }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className='relative mx-auto'>
      <div className='overflow-hidden'>
        <div
          className='flex transition-transform duration-1000 aspect-auto'
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={"/Images/background/" + image}
              alt={`Slide ${index + 1}`}
              className='w-full h-auto flex-shrink-0 object-contain'
            />
          ))}
          <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-20'></div>
        </div>
      </div>

      <div className='absolute bottom-0 left-0 right-0 flex justify-center pb-2 sm:pb-4 lg:pb-8'>
        {images.map((_, index) => (
          <span
            key={index}
            onClick={() => handleDotClick(index)}
            className={`mx-1 w-2 h-2 sm:w-3 sm:h-3 rounded-full cursor-pointer ${
              index === currentIndex ? "bg-red-600" : "bg-gray-300"
            }`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
