import React, { CSSProperties } from "react";
import DashboardSidebar from "../../Components/Layouts/DashboardSidebar/dashboardsidebar";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { API } from "../../API/API";
import { ConfirmationModal } from "../../Components/Layouts/ConfirmationBox/confirmationbox";
import MoonLoader from "react-spinners/MoonLoader";

interface Product {
  id: number;
  name: string;
  images: string;
  type: { name: string; id: number };
  brand: { name: string; id: number };
}

interface ContImageProps {
  image: string;
}

interface BrandProps {
  name: string;
  id: number;
}

const ContImage: React.FC<ContImageProps> = React.memo(({ image }) => {
  return (
    <img
      className='relative rounded-lg aspect-square object-cover w-32 mx-auto'
      src={image}
      alt=''
      loading='lazy'
    />
  );
});

const DashboardProduct = () => {
  const imagePlaceholder = "/Images/imagePlaceholder.png";

  const [loading, setLoading] = React.useState(false);

  const [action, setAction] = React.useState("None");
  const [search, setSearch] = React.useState("");

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("");
  const [confirmCallback, setConfirmCallback] = React.useState<
    null | ((result: boolean) => void)
  >(null);

  const [maxRow, setMaxRow] = React.useState(0);
  const [fetchRow, setFetchRow] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const [brand, setBrand] = React.useState<BrandProps[]>([]);
  const [product, setProduct] = React.useState<Product[]>([]);

  const [currBrand, setCurrBrand] = React.useState<{
    id: number;
    name: string;
  } | null>(null);
  const [currId, setCurrId] = React.useState(0);
  const [currName, setCurrName] = React.useState("");
  const [currImage, setCurrImage] = React.useState(imagePlaceholder);
  const [imageToUpload, setImageToUpload] = React.useState<File | null>(null);

  const scrollToTopPage = () => {
    const topElement = document.getElementById("topPage");
    if (topElement) {
      topElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "white",
  };

  const getProduct = async () => {
    try {
      const response = await API.get("product");
      setProduct(response.data);
      setMaxRow(response.data.length - 1);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
    }
  };

  const getBrand = async () => {
    const response = await API.get("/brand");
    setBrand(response.data);
    try {
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
    }
  };

  const maxRowInitial = () => {
    setMaxRow(
      search === ""
        ? product.length
        : product.filter((item) =>
            (item.name || "")
              .toLowerCase()
              .includes((search || "").toLowerCase())
          ).length
    );
  };

  const clearCurrForm = () => {
    setCurrBrand(null);
    setCurrBrand(null);
    setCurrName("");
    setCurrImage("");
    setCurrId(0);
    setCurrImage(imagePlaceholder);
  };

  React.useEffect(() => {
    maxRowInitial();
  }, [product]);

  React.useEffect(() => {
    getProduct();
    getBrand();
    maxRowInitial();
  }, []);

  React.useEffect(() => {
    maxRowInitial();
    setFetchRow(0);
  }, [search]);

  const handleAddClick = () => {
    setAction("Add");
  };

  const handleEditClick = (item: {
    id: number;
    name: string;
    images: string;
    type: { name: string; id: number };
    brand: { name: string; id: number };
  }) => {
    setAction("Edit");
    setCurrBrand(item.brand);
    setCurrName(item.name);
    setCurrImage(item.images);
    setCurrId(item.id);
  };

  const handleDeleteClick = (idx: number) => {
    setModalMessage("Are you sure you want to delete this product?");
    setModalOpen(true);

    setConfirmCallback(() => (result: boolean) => {
      setModalOpen(false);
      if (result) {
        setLoading(true);
        deleteProduct(idx);
      }
    });
  };

  const handleBrandChange = (brand: { id: number; name: string }) => {
    setCurrBrand(brand);
  };

  const handleImageChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      setCurrImage(URL.createObjectURL(file));
      setImageToUpload(file);
    }
  };

  const createProduct = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currName.trim() === "") {
      toast.error("Product name can't be empty", {
        duration: 3000,
      });
      return;
    }

    if (currName.length > 100) {
      toast.error("Product name too long (Max 100 Character)", {
        duration: 3000,
      });
      return;
    }

    if (currBrand === null) {
      toast.error("Please select product brand", {
        duration: 3000,
      });
      return;
    }

    if (imageToUpload === null) {
      toast.error("Please select product image", {
        duration: 3000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", currName);
    if (imageToUpload) {
      formData.append("images", imageToUpload);
    }
    formData.append("brandId", currBrand?.id.toString() || "");

    setModalMessage("Are you sure you want to create this product?");
    setModalOpen(true);

    setConfirmCallback(() => async (result: boolean) => {
      setModalOpen(false);
      if (result) {
        setLoading(true);
        try {
          const response = await API.post("product", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data.message) {
            toast.success(response.data.message, {
              duration: 3000,
            });
            clearCurrForm();
            setAction("None");

            setProduct([]);
            getProduct();
          }
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response) {
              toast.error(error.response.data.message, { duration: 3000 });
            } else {
              toast.error("An error occurred: " + error.message, {
                duration: 3000,
              });
            }
          } else {
            toast.error("An unknown error occurred", { duration: 3000 });
          }
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const updateProduct = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currName.trim() === "") {
      toast.error("Product name can't be empty", {
        duration: 3000,
      });
      return;
    }

    if (currName.length > 100) {
      toast.error("Product name too long (Max 100 Character)", {
        duration: 3000,
      });
      return;
    }

    if (currBrand?.id === null) {
      toast.error("Product brand can't be empty. Please select product brand", {
        duration: 3000,
      });
      return;
    }

    if (imageToUpload === null && currImage.trim() === "") {
      toast.error("Product image can't be empty. Please select product image", {
        duration: 3000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", currName);
    if (imageToUpload) {
      formData.append("images", imageToUpload);
    }
    formData.append("brandId", currBrand?.id.toString() || "");

    setModalMessage("Are you sure you want to update this product?");
    setModalOpen(true);

    setConfirmCallback(() => async (result: boolean) => {
      setModalOpen(false);
      if (result) {
        setLoading(true);
        try {
          const response = await API.patch(`product/${currId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data.message) {
            toast.success(response.data.message, {
              duration: 3000,
            });
            clearCurrForm();
            setAction("None");

            setProduct([]);
            getProduct();
          }
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response) {
              toast.error(error.response.data.message, { duration: 3000 });
            } else {
              toast.error("An error occurred: " + error.message, {
                duration: 3000,
              });
            }
          } else {
            toast.error("An unknown error occurred", { duration: 3000 });
          }
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const deleteProduct = async (id: number) => {
    try {
      const response = await API.delete(`product/${id}`);
      if (response.data.message) {
        toast.success(response.data.message, {
          duration: 3000,
        });

        setProduct([]);
        getProduct();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          toast.error(error.response.data.message, { duration: 3000 });
        } else {
          toast.error("An error occurred: " + error.message, {
            duration: 3000,
          });
        }
      } else {
        toast.error("An unknown error occurred", { duration: 3000 });
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onClickPrevious = () => {
    // Hitung nilai baru fetchRow
    const newFetchRow = fetchRow - limit;

    // Jika nilai baru kurang dari 0, setel fetchRow ke 0
    if (newFetchRow < 0) {
      setFetchRow(0);
    } else {
      setFetchRow(newFetchRow);
    }
  };

  const onClickNext = () => {
    // Hitung nilai baru fetchRow
    const newFetchRow = fetchRow + limit;

    // Jika nilai baru melebihi maxRow, setel fetchRow ke maxRow
    if (newFetchRow >= maxRow) {
      setFetchRow(maxRow); // Set fetchRow ke maxRow jika sudah melebihi
    } else {
      setFetchRow(newFetchRow); // Set fetchRow ke newFetchRow jika tidak melebihi
    }
  };

  return (
    <div className='bg-white min-h-screen flex flex-row'>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className='my-20 w-20 mx-auto col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-5'>
            <MoonLoader
              loading={loading}
              cssOverride={override}
              data-testid='loader'
              speedMultiplier={0.5}
            />
          </div>
        </div>
      )}
      {isModalOpen && (
        <ConfirmationModal
          message={modalMessage}
          onConfirm={(result) => {
            if (confirmCallback) confirmCallback(result);
          }}
        />
      )}
      <DashboardSidebar />
      <div className='max-w-screen-xl mx-auto px-4 md:px-8 my-8'>
        <div className='items-start justify-between md:flex'>
          <div className='max-w-xl lg:max-w-2xl'>
            <h3
              className='text-gray-800 text-xl font-bold sm:text-2xl'
              id='topPage'
            >
              Product Management
            </h3>
            <p className='text-gray-400 mt-2 text-xs lg:text-sm max-w-xl'>
              Product management involves overseeing the development, launch,
              and lifecycle of a product to ensure it aligns with market needs
              and business objectives through strategic planning and execution.
            </p>
          </div>
          <div className='mt-3 md:mt-0'>
            <button
              onClick={() => {
                handleAddClick();
                clearCurrForm();
              }}
              className='inline-block px-4 py-2 text-primary duration-150 font-medium bg-green-500 hover:bg-green-600 active:bg-green-700 rounded-lg md:text-sm truncate'
            >
              Add Product
            </button>
          </div>
        </div>

        {action === "Add" ? (
          <form className='mt-8' onSubmit={createProduct}>
            <div className='grid'>
              <div className='flex flex-row justify-between place-items-center bg-green-200 rounded-lg p-3'>
                <p className='text-xl font-semibold'>Add New Product</p>
                <img
                  src='/Images/icons8-close-100.png'
                  alt='icon'
                  className='object-cover w-4 lg:w-6 cursor-pointer'
                  onClick={() => {
                    setAction("None");
                    clearCurrForm();
                  }}
                />
              </div>
              <p className='text-gray-400 mt-2 text-xs max-w-2xl lg:text-sm mb-4 mr-8'>
                Adding a new product to our catalog involves a meticulous
                process to ensure each product meets our quality standards and
                customer expectations. In this section, you will provide all
                necessary details about the new product, including its name,
                category, and images.
              </p>
              <div className='flex flex-row gap-2 mb-8 mx-auto'>
                <div className='w-64 flex'>
                  <img
                    className='rounded-lg aspect-square object-cover w-52 self-center mx-auto'
                    src={currImage}
                    loading='lazy'
                    alt='product'
                  />
                </div>
                <div className='flex flex-col gap-2 '>
                  <div className='w-full'>
                    <label
                      htmlFor='product_name'
                      className='block mb-2 text-sm font-medium text-gray-900'
                    >
                      Product Name
                    </label>
                    <input
                      type='text'
                      id='product_name'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5'
                      placeholder='Product Name'
                      value={currName}
                      onChange={(e) => setCurrName(e.target.value)}
                      required
                    />
                  </div>
                  <div className='w-full'>
                    <label
                      className='block mb-2 text-sm font-medium text-gray-900'
                      htmlFor='product_image'
                    >
                      Product Brand
                    </label>
                    <select
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5'
                      value={currBrand ? currBrand.id : ""}
                      onChange={(e) => {
                        const selectedBrand = brand.find(
                          (brand) => brand.id === parseInt(e.target.value)
                        );
                        if (selectedBrand) {
                          handleBrandChange(selectedBrand);
                        }
                      }}
                    >
                      {/* Placeholder option */}
                      {!currBrand && (
                        <option value='' disabled className='text-gray-400 '>
                          Select Product Brand
                        </option>
                      )}
                      {brand.map((brand) => (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='w-full'>
                    <label
                      className='block mb-2 text-sm font-medium text-gray-900'
                      htmlFor='product_image'
                    >
                      Upload Image
                    </label>
                    <input
                      type='file'
                      accept='image/*'
                      className='block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 p-2'
                      id='product_image'
                      onChange={(e) => handleImageChange(e.target.files)}
                    />
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='text-white bg-green-500 hover:bg-green-600 active:bg-green-700 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center mx-auto px-20'
              >
                Add
              </button>
            </div>
          </form>
        ) : action === "Edit" ? (
          <form className='mt-8' onSubmit={updateProduct}>
            <div className='grid'>
              <div className='flex flex-row justify-between place-items-center bg-yellow-100 rounded-lg p-3'>
                <p className='text-xl font-semibold'>Edit Product</p>
                <img
                  src='/Images/icons8-close-100.png'
                  alt='icon'
                  className='object-cover w-4 lg:w-6 cursor-pointer'
                  onClick={() => {
                    setAction("None");
                    clearCurrForm();
                  }}
                />
              </div>

              <p className='text-gray-400 mt-2 text-xs mr-8 lg:text-sm mb-4 max-w-2xl'>
                Editing a product allows you to update and refine the details of
                existing products to ensure they remain relevant and appealing
                to our customers. In this section, you can modify the product's
                name, category, images, and any other pertinent information.
              </p>
              <div className='flex flex-row gap-2 mb-8 mx-auto'>
                <div className='w-64 flex'>
                  <img
                    className='rounded-lg aspect-square object-cover w-52 self-center mx-auto'
                    src={currImage}
                    loading='lazy'
                    alt='product'
                  />
                </div>
                <div className='flex flex-col gap-2 '>
                  <div className='w-full'>
                    <label
                      htmlFor='product_name'
                      className='block mb-2 text-sm font-medium text-gray-900'
                    >
                      Product Name
                    </label>
                    <input
                      type='text'
                      id='product_name'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5'
                      placeholder='Product Name'
                      value={currName}
                      onChange={(e) => setCurrName(e.target.value)}
                      required
                    />
                  </div>
                  <div className='w-full'>
                    <label
                      className='block mb-2 text-sm font-medium text-gray-900'
                      htmlFor='product_image'
                    >
                      Product Brand
                    </label>
                    <select
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5'
                      value={currBrand ? currBrand.id : ""}
                      onChange={(e) => {
                        const selectedBrand = brand.find(
                          (brand) => brand.id === parseInt(e.target.value)
                        );
                        if (selectedBrand) {
                          handleBrandChange(selectedBrand);
                        }
                      }}
                    >
                      {/* Placeholder option */}
                      {!currBrand && (
                        <option value='' disabled>
                          Select Product Brand
                        </option>
                      )}
                      {brand.map((brand) => (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='w-full'>
                    <label
                      className='block mb-2 text-sm font-medium text-gray-900'
                      htmlFor='product_image'
                    >
                      Upload Image
                    </label>
                    <input
                      type='file'
                      accept='image/*'
                      className='block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 p-2'
                      id='product_image'
                      onChange={(e) => handleImageChange(e.target.files)}
                    />
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='text-white bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-600 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center mx-auto px-20'
              >
                Update
              </button>
            </div>
          </form>
        ) : (
          ""
        )}

        <div className='my-4 flex place-content-end'>
          <label
            htmlFor='default-search'
            className='mb-2 text-sm font-medium text-gray-900 sr-only'
          >
            Search
          </label>
          <div className='relative'>
            <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
              <svg
                className='w-4 h-4 text-gray-500'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 20 20'
              >
                <path
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                />
              </svg>
            </div>
            <input
              value={search}
              type='search'
              id='default-search'
              className='w-64 p-2.5 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50'
              placeholder='Search by Product Name'
              onChange={(e) => {
                setSearch(e.target.value);
                setFetchRow(0);
              }}
            />
          </div>
        </div>

        <div
          className='my-4 shadow-sm border rounded-lg overflow-x-auto relative'
          style={{ maxHeight: "400px" }}
        >
          <table className='w-full table-auto overflow-g text-sm text-left'>
            <thead className='bg-gray-50 text-gray-600 font-medium border-b sticky top-0 z-10'>
              <tr>
                <th className='py-3 px-6 text-center'>Product Name</th>
                <th className='py-3 px-6 text-center'>Product Brand</th>
                <th className='py-3 px-6 text-center truncate'>
                  Product Image
                </th>
                <th className='py-3 px-6 text-center'>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 divide-y text-center'>
              {product && product.length > 0 ? (
                search === "" ? (
                  product.slice(fetchRow, fetchRow + limit).map((item, idx) => (
                    <tr key={idx}>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {item.name}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {item.brand.name}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap z-0'>
                        <ContImage image={item.images} />
                      </td>
                      <td className='text-center px-6 whitespace-nowrap'>
                        <button
                          onClick={() => {
                            clearCurrForm();
                            handleEditClick(item);
                            scrollToTopPage();
                          }}
                          className='py-2 px-3 font-medium text-third hover:text-yellow-500 duration-150 hover:bg-gray-50 rounded-lg'
                        >
                          Edit
                        </button>
                        |
                        <button
                          onClick={() => handleDeleteClick(item.id)}
                          className='py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg'
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : product.filter((item) =>
                    (item.name || "")
                      .toLowerCase()
                      .includes((search || "").toLowerCase())
                  ).length > 0 ? (
                  product
                    .filter((item) =>
                      (item.name || "")
                        .toLowerCase()
                        .includes((search || "").toLowerCase())
                    )
                    .slice(fetchRow, fetchRow + limit)
                    .map((item, idx) => (
                      <tr key={idx}>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          {item.name}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          {item.brand.name}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap truncate max-w-0'>
                          <ContImage image={item.images} />
                        </td>
                        <td className='text-center px-6 whitespace-nowrap'>
                          <button
                            onClick={() => {
                              clearCurrForm();
                              handleEditClick(item);
                              scrollToTopPage();
                            }}
                            className='py-2 px-3 font-medium text-third hover:text-yellow-500 duration-150 hover:bg-gray-50 rounded-lg'
                          >
                            Edit
                          </button>
                          |
                          <button
                            onClick={() => handleDeleteClick(item.id)}
                            className='py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg'
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td className='px-6 py-4 whitespace-nowrap'>No Data</td>
                    <td className='px-6 py-4 whitespace-nowrap'>No Data</td>
                    <td className='px-6 py-4 whitespace-nowrap'>No Data</td>
                    <td className='text-center px-6 whitespace-nowrap'>
                      No Data
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td className='px-6 py-4 whitespace-nowrap'>No Data</td>
                  <td className='px-6 py-4 whitespace-nowrap'>No Data</td>
                  <td className='px-6 py-4 whitespace-nowrap'>No Data</td>
                  <td className='text-center px-6 whitespace-nowrap'>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <nav
          aria-label='Page navigation example'
          className='flex w-full place-items-center place-content-center'
        >
          <ul className='inline-flex -space-x-px text-lg '>
            <li>
              <button
                onClick={() => onClickPrevious()}
                disabled={fetchRow <= 0 ? true : false}
                className='flex items-center justify-center px-6 h-12 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700'
              >
                Previous
              </button>
            </li>
            <li>
              <button
                disabled
                className='flex items-center justify-center px-6 h-12 leading-tight text-gray-500 bg-white border border-gray-300'
              >
                Row {Math.min(fetchRow + 1, maxRow)} -{" "}
                {Math.min(fetchRow + limit, maxRow)} / {maxRow}
              </button>
            </li>
            <li>
              <button
                onClick={() => onClickNext()}
                disabled={fetchRow + limit >= maxRow}
                className='flex items-center justify-center px-6 h-12 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700'
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default DashboardProduct;
